// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/Main.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/Main.tsx");
  import.meta.hot.lastModified = "1720262735808.808";
}
// REMIX HMR END

import { useState } from "react";
import { Slider } from "./ui/slider";
function Main({
  data
}) {
  _s();
  const [isAdvanced, setIsAdvanced] = useState(false);
  const onChangeHandler = value => {
    // fix types
    //@ts-ignore
    setValue(formatNumber(value));
  };
  const [value, setValue] = useState([1200000]);
  return <div>
      {/* why-us section  */}
      <div className="container sm:pt-60">
        <h1 className="text-4xl font-black text-center py-10">Fordeler med tjenesten</h1>
        <div className="flex flex-col justify-center sm:px-6 sm:flex-row">
          <div className="sm:w-1/4 text-center p-7 flex  flex-col justify-center">
            <div className="bg-[url('./images/why-us/telescope.png')] w-32 h-32 sm:w-40 sm:h-40 bg-contain m-auto"></div>
            <h2 className="text-2xl font-bold py-3 sm:py-5">Enkelt</h2>
            <p className="px-3 min-h-24">
              Fyll ut skjema og sammenlign tilbud fra flere eiendomsmeglere, helt gratis og uforpliktende.
            </p>
          </div>
          <div className="sm:w-1/4 text-center p-7 flex  flex-col justify-center">
            <div className="bg-[url('./images/why-us/arrow-target.png')] w-32 h-32 sm:w-40 sm:h-40 bg-contain m-auto"></div>
            <h2 className="text-2xl font-bold py-3 sm:py-5">Spar tid</h2>
            <p className="px-3 min-h-24">
              Ikke kast bort tid på å lete fram tilbud selv. La oss gjøre jobben for deg!
            </p>
          </div>
          <div className="sm:w-1/4 text-center p-7 flex  flex-col justify-center">
            <div className="bg-[url('./images/why-us/mechanic.png')] w-32 h-32 sm:w-40 sm:h-40 bg-contain m-auto"></div>
            <h2 className="text-2xl font-bold py-3 sm:py-5">Spar penger</h2>
            <p className="px-3 min-h-24">
              Eiendomsmeglere vet de konkurrerer om deg som kunde, og og ønsker derfor å gi deg et godt tilbud.</p>
          </div>
        </div>
        <div className="m-auto w-fit bg-emerald-700 hover:bg-emerald-600 rounded-md px-3 py-2 text-white">
          <button>Få tilbud nå!</button>
        </div>
      </div>

      {/* latest calculations */}
      <div className="container sm:pt-16       hidden">
        <h1 className="text-4xl font-black text-center py-10">Latest Calculations</h1>
        <div>
          <div className="w-full flex justify-center">
            {data && data.latestCalculation.map((item, i) => <div className="flex flex-col w-1/4 p-7" key={i}>
                <div className="flex flex-col bg-green-100 rounded-md">
                  <div className="p-3 mx-2">
                    <span>Applied for:</span>
                    <span className="float-right font-bold">NOK {item.applied}</span>
                  </div>
                  <div className="p-3 mx-2">
                    <span>Number of offers:</span>
                    <span className="float-right font-bold">{item.offers}</span>
                  </div>
                  <div className="p-3 mx-2">
                    <span>Lowest interest rate:</span>
                    <span className="float-right font-bold">{item.rate}%</span>
                  </div>
                  <div className="p-3 mx-2">
                    <span>Total savings:</span>
                    <span className="float-right font-bold text-emerald-700">NOK {item.save}</span>
                  </div>
                  <div className="p-3 mx-2">
                    <span>Best bank:</span>
                    <span className="float-right font-bold">{item.bank}</span>
                  </div>
                </div>
                <div className="flex items-center py-3">
                  <div className="bg-[url('./images/latest-calc/person-1.svg')] w-12 h-12 bg-contain"></div>
                  <div className="font-bold">{`${item.name}, ${item.age}`}</div>
                </div>
              </div>)}
          </div>
        </div>
      </div>

      {/* check offers */}
      <div className="container bg-orange-50 sm:w-8/12 flex my-9 sm:flex-row flex-col">
        <div className="sm:w-5/12 bg-[url('./images/landscape.png')] w-full h-[450px] bg-contain bg-no-repeat sm:m-5">
          <div className="bg-[url('./images/trophy.svg')] bg-contain w-1/2 h-4/6 bg-no-repeat transform scale-x-[-1] translate-x-1/4 translate-y-1/3 rotate-[20deg]"></div>
        </div>
        <div className="sm:w-1/2 flex flex-col justify-center sm:p-16">
          <div className="font-extrabold text-4xl py-4">Å finne riktig eiendomsmegler har aldri vært enklere</div>
          <div className="text-lg py-4">Å finne den riktige eiendomsmegler er ofte tidkrevende og det er vanskelig å vite om man velger den riktige. Vi har laget en stor database ber om tilbud hos opp til 3 byråer som har erfaring med din type bolig i ditt nærområde.</div>
          <div className="bg-emerald-700 hover:bg-emerald-600 rounded-3xl py-3 my-3 text-white w-fit">
            <button className="flex px-4">Be om tilbud nå</button>
          </div>
        </div>
      </div>

      {/* process */}
      <div className=" mb-9">
        <h1 className="text-4xl font-black text-center py-10">Slik henter vi inn tilbud</h1>
        <div className="flex px-6 justify-center flex-col sm:flex-row">
          <div className="sm:w-1/4 text-center p-7 flex  flex-col justify-center">
            <div className="bg-[url('./images/process/comments.svg')] w-40 h-40 bg-contain m-auto"></div>
            <h2 className="text-2xl font-bold py-5">1. Fyll ut skjema
          </h2>
            <p className="px-3 min-h-24">
              Fyll ut vårt skjea med informasjon om deres bolig.</p>
          </div>
          <div className="sm:w-1/4 text-center p-7 flex  flex-col justify-center">
            <div className="bg-[url('./images/process/contract.svg')] w-40 h-40 bg-contain m-auto"></div>
            <h2 className="text-2xl font-bold py-5">2. Vi videreformidler din forespørsel</h2>
            <p className="px-3 min-h-24">
              Vi matcher deg og sender forespørsel til med ulike eiendomsmeglere bassert på hvilken type bolig, lokasjon, salgshistorikk til ulike meglere mm.</p>
          </div>
          <div className="sm:w-1/4 text-center p-7 flex  flex-col justify-center">
            <div className="bg-[url('./images/process/telescope.svg')] w-40 h-40 bg-contain m-auto"></div>
            <h2 className="text-2xl font-bold py-5">3. Mota tilbud</h2>
            <p className="px-3 min-h-24">
              Du vil motta en forespørsel fra ulike eiendomsmeglere og du kan velge selv hvem du ønsker å invitere til en verdivurdering av din bolig.
            </p>
          </div>
        </div>
        <div className="m-auto w-fit bg-emerald-700 hover:bg-emerald-600 rounded-md px-3 py-2 text-white">
          <button>Kom i gang</button>
        </div>
      </div>

      {/* loan */}
      <div className="container bg-orange-50 sm:w-8/12 flex flex-col sm:flex-row py-10 justify-around hidden">
        <div className="sm:w-2/5 flex flex-col justify-center ">
          <div className="bg-[url('./images/why-us/mechanic.png')] w-28 h-28 bg-contain m-auto scale-x-[-1]"></div>
          <div className="font-extrabold text-4xl py-4">Simple prices so you can focus on more important things</div>
          <div className="text-lg py-4">The vast majority of companies periodically need new capital to finance new investments and to ensure liquidity in periods of growth or uncertainty.</div>
        </div>
        <div className="sm:w-2/5 bg-orange-300 p-3">
          <div className="flex justify-between items-center px-3">
            <div className="bg-[url('./images/plus-minus.svg')] w-6 h-6 bg-no-repeat mr-2"></div>
            <span className="font-bold text-sm">PRICE EXAMPLE LOAN AMOUNT:</span>
          </div>
          <div className="p-3">
            <div className="py-4">
              <Slider defaultValue={value} max={5000000} step={1000} onValueChange={onChangeHandler} className="pb-4" />
              <span className="font-extrabold text-3xl my-3">{value}</span>
            </div>
          </div>
          <div className="p-3">
            <span className="font-bold text-sm uppercase">monthly cost:</span>
            <div className="py-2">
              <div className="py-1">
                <span>Monthly interest</span>
                <span className="float-right font-bold">5,5%</span>
              </div>
              <div className="py-1">
                <span>Monthly fee</span>
                <span className="float-right font-bold">12,000</span>
              </div>
              <div className="py-1">
                <span>Total</span>
                <span className="float-right font-bold text-emerald-700">24,000</span>
              </div>
            </div>
            <div className="m-auto bg-emerald-700 hover:bg-emerald-600 rounded-3xl py-2 my-3 text-white">
              <button className="m-auto flex px-4">Check offers for your company</button>
            </div>
          </div>
        </div>
      </div>

      {/* our partners */}
      <div className="container py-7">
        <h1 className="text-4xl font-black text-center py-10">Vi jobber med bransjens beste meglere</h1>
        <div className="sm:px-32">
          <div className="sm:bg-[url('./images/partners.png')] bg-[url('./images/partners-mobile.png')] w-full h-48 sm:h-[235px] bg-contain bg-no-repeat"></div>
        </div>
        <div className="text-center text-emerald-700 text-lg font-bold sm:p-10">Se alle meglerkontorer</div>
      </div>

      {/* reviews */}
      <div className="container sm:pt-16 bg-sky-200      hidden">
        <h1 className="text-4xl font-black text-center py-10">Reviews</h1>
        <div>
          <div>
            <div className="w-full flex justify-center">
              {data && data.reviews.map(item => <div className="flex flex-col w-1/3 p-3 " key={item.id}>
                  <div className="flex flex-col justify-between bg-white rounded-md h-40 p-5">
                    <div>{item.text}</div>
                    <div className="flex justify-between">
                      <div className="text-emerald-700 expand-border">See more</div>
                      <div className="bg-[url('./images/reviews/g-icon.svg')] w-5 h-5 bg-contain "></div>
                    </div>
                  </div>
                  <div className="flex items-center py-3 align-middle">
                    <div className="pr-5">
                      <div className="bg-[url('./images/reviews/partner-1.png')] w-14 h-14 bg-contain "></div>
                      <div className="bg-[url('./images/reviews/like.svg')] w-7 h-7 bg-contain relative left-8 bottom-5"></div>
                    </div>
                    <div className="flex flex-col">
                      <div className="font-bold">{item.name}</div>
                      <div className="font-bold">{item.position}</div>
                    </div>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </div>

      {/* in media */}
      <div className="container py-7 hidden">
        <h1 className="text-4xl font-black text-center py-10">In media</h1>
        <div className="sm:px-32">
          <div className="bg-[url('./images/mob-media1.png')] w-full h-20 bg-contain bg-no-repeat sm:hidden"></div>
          <div className="sm:bg-[url('./images/partners-media.png')] bg-[url('./images/mob-media2.png')] w-full h-20 sm:h-24 bg-contain bg-no-repeat"></div>
        </div>
      </div>

      {/* check offers */}
      <div className="container bg-orange-50 sm:w-8/12 flex sm:flex-row flex-col my-9">
        <div className="sm:w-5/12 bg-[url('./images/smile-face.png')] w-full h-[350px] sm:h-[450px] bg-contain bg-no-repeat sm:m-5">
          {/* <div className="bg-[url('./images/trophy.svg')] bg-contain w-1/2 h-4/6 bg-no-repeat transform scale-x-[-1] translate-x-1/4 translate-y-1/3 rotate-[20deg]"></div> */}
        </div>
        <div className="sm:w-1/2 flex flex-col justify-center sm:p-16">
          <div className="font-extrabold text-4xl py-4">Finn den megleren som står på for å selge din bolig!</div>
          <div className="text-lg py-4">Vi har samlet en større database av meglere og henter vi inn tilbud fra de som passer så godt som mulig til din eiendom.</div>
          <div className="bg-emerald-700 hover:bg-emerald-600 rounded-3xl py-3 my-3 text-white w-fit">
            <button className="flex px-4">Fyll ut skjemaet og motta tilbud</button>
          </div>
        </div>
      </div>

      {/* Goal */}
        <div className="container py-7 flex flex-col items-center">
            <div className="bg-[url('./images/goal.png')] w-40 h-40 bg-contain"></div>
            <h1 className="text-3xl font-extrabold text-center py-10 sm:w-4/6">Finn en eiendomsmegler i ditt nærområde</h1>

            <div className="text-lg text-center container w-4/6 py-4">Se etter lokale eiendomsmeglere i nærheten av deg og få uforpliktende, gratis og gode tilbud..
            </div>

            <div className="bg-emerald-700 hover:bg-emerald-600 rounded-3xl py-3 my-3 text-white w-fit">
                <button className="flex px-4">Finn eiendomsmegler</button>
            </div>
        </div>

    </div>;
}
_s(Main, "nYK2zVbVJkT+6shvYr/FfOvWTLQ=");
_c = Main;
export default Main;
var _c;
$RefreshReg$(_c, "Main");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;