// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/TopSection.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/TopSection.tsx");
  import.meta.hot.lastModified = "1720262735809.808";
}
// REMIX HMR END

import TopFormContent from "./TopForm";
function TopSection() {
  return <div className="bg-[url('./images/top-section.png')] bg-no-repeat  bg-cover w-full">
      <div className="sm:h-[600px] w-full md:flex justify-center items-center sm:pt-16">
        <div className="max-w-full sm:h-[644px] transparent flex flex-col items-center">
          <div className="sm:p-7  sm:w-1/2 text-center">
            <div className="rounded-xl bg-emerald-900 w-fit m-auto">
              <p className="px-3 text-white text-center">
                Få tilbud fra opptil <span className="text-yellow-500">3 </span> meglere, tjenesten er
                <span className="text-yellow-500"> gratis </span> og <span className="text-yellow-500"> uforpliktende </span>
              </p>
            </div>
            <p className="text-6xl font-black text-center text-white py-3">Få tilbud fra flere eiendomsmeglere</p>
            <p className="text-2xl text-center text-white font-normal py-3">Fyll ut og la meglerene kjempe om deg! Vår tjeneste er rask og enkel og gir deg trygghet for at du velger det beste tilbudet. </p>
          </div>
          <TopFormContent value="loans" />
        </div>
      </div>

    </div>;
}
_c = TopSection;
export default TopSection;
var _c;
$RefreshReg$(_c, "TopSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;